/**
 * Created by boris on 3/23/17.
 */

import {
  MAIN_URL,
  SET_EDIT_STORY,
  FORBIDDEN,
  SET_EDIT_STORY_INITIAL,
} from '../actions/constants'
import axiosClient from '../axiosClient'

export function setEditStory(story, actionType) {
  if (story === undefined) {
    throw new Error('setEditStory: story is undefined')
  }
  if (actionType === undefined) {
    throw new Error('setEditStory: actionType is undefined')
  }
  return {
    type: SET_EDIT_STORY,
    story,
    actionType,
  }
}

export function setEditStoryInitial() {
  return {
    type: SET_EDIT_STORY_INITIAL,
  }
}

export function loadEditStory(id) {
  return async dispatch => {
    try {
      const response = await axiosClient.get(`${MAIN_URL}/stories/${id}`)
      console.log(response.data)
      dispatch(setEditStory(response.data, SET_EDIT_STORY))
    } catch (err) {
      console.log('err', err)
      if (err.response.status === FORBIDDEN) dispatch(setEditStory())
    }
  }
}

export function submitEditStory(data, id) {
  return dispatch => {
    return axiosClient
      .put(`${MAIN_URL}/stories/${id}`, data)
      .then(response => dispatch(setEditStory()))
      .catch(err => {
        if (err.response.status === FORBIDDEN) dispatch(setEditStory())
      })
  }
}
