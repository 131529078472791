/**
 * Created by borysyudin on 14.02.17.
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { Link } from 'react-router'
import { connect } from 'react-redux'

import moment from 'moment'

import { setShowUserCurrentPosition } from '../actions/ShowUserActions'

import '../css/UserInfo.css'
import { Box } from '@material-ui/core'

class UserInfoPopoverMenu extends React.Component {
  render() {
    const {
      anchorElement,
      children,
      handleCloseDropDown,
      ...menuProps
    } = this.props

    return (
      <Menu
        className="popover-action"
        open={Boolean(anchorElement)}
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClose={handleCloseDropDown}
        {...menuProps}
      >
        {children}
      </Menu>
    )
  }
}
class UserInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorElement: null,
    }
    this.handleOpenDropDown = this.handleOpenDropDown.bind(this)
    this.handleCloseDropDown = this.handleCloseDropDown.bind(this)
  }

  getFullName(user) {
    return user.first_name + ' ' + user.last_name
  }

  getCreatedDate(date) {
    let SEC_IN_DAY = 86399 // Seconds in day - 1 second
    if (Math.abs(moment().diff(date)) < SEC_IN_DAY * 1000) {
      // 1000 milliseconds
      return moment(date).fromNow()
    }
    return `${date.getDate()} ${date.toLocaleString('en-us', {
      month: 'short',
    })} ${date.getFullYear()}`
  }

  handleOpenDropDown = event => {
    event.preventDefault()
    this.setState({
      anchorElement: event.currentTarget,
    })
  }

  handleCloseDropDown = () => {
    console.log('click away')
    this.setState({
      anchorElement: null,
    })
  }

  render() {
    const {
      storyUser,
      created_at,
      rank,
      preview,
      handleEvent,
      commentLikesEvent,
      editStory,
      setShowUserCurrentPosition,
    } = this.props

    const { anchorElement } = this.state
    let created_date = null
    let currentUser = this.props.auth.user
    const { isAuthenticated } = this.props.auth

    if (created_at) {
      created_date = new Date(created_at)
      created_date =
        String.fromCharCode(183) + ' ' + this.getCreatedDate(created_date)
    }

    let username = '@' + storyUser.username + ' '
    let commentEllipseDots = null
    let blockUserProps = null
    let reportStoryProps = null
    let reportCommentProps = null

    if (!preview) {
      blockUserProps = () =>
        this.setState({ anchorElement: null }, () => handleEvent('BLOCK_USER'))
      reportStoryProps = () =>
        this.setState({ anchorElement: null }, () =>
          handleEvent('REPORT_STORY')
        )
    }

    let menuItems = (
      <UserInfoPopoverMenu
        anchorElement={anchorElement}
        handleCloseDropDown={this.handleCloseDropDown}
      >
        <MenuItem onClick={reportStoryProps}>Report post</MenuItem>
        <MenuItem onClick={blockUserProps}>Block user</MenuItem>
      </UserInfoPopoverMenu>
    )
    let commentPopoverMenuItems = (
      <UserInfoPopoverMenu
        anchorElement={anchorElement}
        handleCloseDropDown={this.handleCloseDropDown}
      >
        <MenuItem onClick={reportCommentProps}>Report comment</MenuItem>
        <MenuItem onClick={blockUserProps}>Block user</MenuItem>
      </UserInfoPopoverMenu>
    )

    if (currentUser && storyUser.id === currentUser.id) {
      commentEllipseDots = (
        <i
          className="fa fa-ellipsis-h comment-dropdown"
          onClick={this.handleOpenDropDown}
          aria-hidden="true"
        />
      )

      let deleteProps = null
      let deleteCommentProps = null
      let editProps = null
      let editCommentProps = null
      let archiveProps = null
      let commentLikesProps = null

      if (!preview) {
        deleteProps = () =>
          this.setState({ anchorElement: null }, () =>
            handleEvent('DELETE_STORY')
          )
        deleteCommentProps = () =>
          this.setState({ anchorElement: null }, () =>
            handleEvent('DELETE_COMMENT')
          )
        editProps = () =>
          this.setState({ anchorElement: null }, () => {
            console.log('editProps from UserInfo')
            editStory()
          })
        editCommentProps = () =>
          this.setState({ anchorElement: null }, () =>
            handleEvent('EDIT_COMMENT')
          )
        archiveProps = () =>
          this.setState({ anchorElement: null }, () =>
            handleEvent('MOVE_TO_ARCHIVE')
          )
        commentLikesProps = () =>
          this.setState({ anchorElement: null }, () =>
            handleEvent('LOAD_COMMENT_LIKES')
          )
      }

      menuItems = (
        <UserInfoPopoverMenu
          anchorElement={anchorElement}
          handleCloseDropDown={this.handleCloseDropDown}
        >
          <MenuItem onClick={editProps}>Edit</MenuItem>
          {rank > 0 && (
            <MenuItem onClick={archiveProps}>Move to archive</MenuItem>
          )}
          <MenuItem onClick={deleteProps}>Delete</MenuItem>
        </UserInfoPopoverMenu>
      )

      commentPopoverMenuItems = (
        <UserInfoPopoverMenu
          anchorElement={anchorElement}
          handleCloseDropDown={this.handleCloseDropDown}
        >
          <MenuItem onClick={editCommentProps}>Edit comment</MenuItem>
          <MenuItem onClick={commentLikesProps}>Likes</MenuItem>
          <MenuItem onClick={deleteCommentProps}>Delete comment</MenuItem>
        </UserInfoPopoverMenu>
      )
    } else if (currentUser && storyUser.id !== currentUser.id) {
      commentEllipseDots = (
        <i
          className="fa fa-ellipsis-h comment-dropdown"
          onClick={this.handleOpenDropDown}
          aria-hidden="true"
        />
      )

      let reportStoryProps = null
      let commentLikesProps = null

      if (!preview) {
        reportStoryProps = () =>
          this.setState({ anchorElement: null }, () =>
            handleEvent('REPORT_COMMENT')
          )
        commentLikesProps = () =>
          this.setState({ anchorElement: null }, () =>
            commentLikesEvent('LOAD_COMMENT_LIKES')
          )
      }

      commentPopoverMenuItems = (
        <UserInfoPopoverMenu
          anchorElement={anchorElement}
          handleCloseDropDown={this.handleCloseDropDown}
        >
          <MenuItem onClick={commentLikesProps}>Likes</MenuItem>
          <MenuItem onClick={reportStoryProps}>Report</MenuItem>
        </UserInfoPopoverMenu>
      )
    }

    let storyEllipseDots = (
      <i
        className="fa fa-ellipsis-v story-dropdown"
        onClick={this.handleOpenDropDown}
        aria-hidden="true"
      />
    )

    let linkTo =
      currentUser && storyUser.id === currentUser.id
        ? `/users/${currentUser.id}`
        : `/users/${storyUser.id}`

    let avatar = (
      <Link to={linkTo} onClick={e => setShowUserCurrentPosition(null)}>
        <Avatar
          src={storyUser.avatar.url}
          style={{ width: '50px', height: '50px' }}
          className="avatar"
        />
      </Link>
    )
    let userName = (
      <Link to={linkTo} onClick={e => setShowUserCurrentPosition(null)}>
        <Box fontWeight="fontWeightBold" marginTop={0.75} display="inlineBlock">
          {this.getFullName(storyUser)}
        </Box>
      </Link>
    )
    if (preview) {
      avatar = (
        <Avatar
          src={storyUser.avatar.url}
          style={{ width: '50px', height: '50px' }}
          className="avatar preview"
        />
      )
      userName = (
        <Box
          className="preview"
          fontWeight="fontWeightBold"
          marginTop={0.25}
          display="inlineBlock"
        >
          {this.getFullName(storyUser)}
        </Box>
      )
    }
    return (
      <div className="user-info">
        {avatar}
        <div className="container">
          {userName}
          {created_at ? (
            <Box className="add-info">
              <span className="user-userName">{username}</span>
              <span className="created-at">{created_date}</span>
            </Box>
          ) : null}
          {!created_at && commentEllipseDots}
          {!created_at && commentPopoverMenuItems}
          {rank > 0 ? (
            rank === 1 ? (
              <div className="number-circle">
                <span>{rank}</span>
              </div>
            ) : (
              <div className="number-circle blue">
                <span>{rank}</span>
              </div>
            )
          ) : null}
          {isAuthenticated && created_at && storyEllipseDots}
          {isAuthenticated && created_at && menuItems}
        </div>
      </div>
    )
  }
}

UserInfo.propTypes = {
  auth: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps, { setShowUserCurrentPosition })(
  UserInfo
)
