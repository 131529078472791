import React from 'react'
import Paper from '@material-ui/core/Paper'
// import UserPageInfo from '../UserPageInfo'
import EditStory from './EditStory'
// import { browserHistory } from 'react-router'
import { loadEditStory } from '../../actions/EditStoryActions'
import { connect } from 'react-redux'

import '../../css/AddStory/AddStory.css'
import { Box } from '@material-ui/core'

class EditStoryPage extends React.Component {
  componentDidMount() {
    const { story_id } = this.props.params
    const { loadEditStory } = this.props
    loadEditStory(story_id)
  }

  render() {
    // const { story_id } = this.props.params
    const { story } = this.props
    console.log('this.props', this.props)

    // const { user } = this.props.auth
    return (
      <Box className="page-content">
        <Paper elevation={8} className="user-page">
          <div className="add-story-content">
            {story && story.id ? <EditStory /> : null}
          </div>
        </Paper>
      </Box>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth:  state.auth,
    story: state.editStory.story,
  }
}

export default connect(mapStateToProps, {
  loadEditStory,
})(EditStoryPage)
