/**
 * Created by boris on 3/23/17.
 */
import { SET_EDIT_STORY, SET_EDIT_STORY_INITIAL } from '../actions/constants'
import isEmpty from 'lodash/isEmpty'

const initialState = {
  actionType: '',
  story:      {},
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_EDIT_STORY_INITIAL: {
      return {
        actionType: '',
        story:      {},
      }
    }

    case SET_EDIT_STORY: {
      let newState = Object.assign({}, initialState)
      console.log('action', action)

      if (!isEmpty(action.story)) {
        newState.story =
          action.actionType === 'SET_SHARE_STORY'
            ? Object.assign({}, action.story, { text: '' })
            : Object.assign({}, action.story)
        newState.actionType = action.actionType
      }

      return newState
    }
    default:
      return state
  }
}
