/**
 * Created by borysyudin on 14.02.17.
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router'
import { connect } from 'react-redux'

import moment from 'moment'

import { setShowUserCurrentPosition } from '../../actions/ShowUserActions'

import '../../css/UserInfo.css'
import { Box } from '@material-ui/core'

class UserInfoPopoverMenu extends React.Component {
  render() {
    const {
      anchorElement,
      children,
      handleCloseDropDown,
      ...menuProps
    } = this.props

    return (
      <Menu
        className="popover-action"
        open={Boolean(anchorElement)}
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClose={handleCloseDropDown}
        {...menuProps}
      >
        {children}
      </Menu>
    )
  }
}
class UserInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorElement: null,
    }
    this.handleOpenDropDown = this.handleOpenDropDown.bind(this)
    this.handleCloseDropDown = this.handleCloseDropDown.bind(this)
  }

  getFullName(user) {
    return user.first_name + ' ' + user.last_name
  }

  getCreatedDate(date) {
    let SEC_IN_DAY = 86399 // Seconds in day - 1 second
    if (Math.abs(moment().diff(date)) < SEC_IN_DAY * 1000) {
      // 1000 milliseconds
      return moment(date).fromNow()
    }
    return `${date.getDate()} ${date.toLocaleString('en-us', {
      month: 'short',
    })} ${date.getFullYear()}`
  }

  handleOpenDropDown = event => {
    event.preventDefault()
    this.setState({
      anchorElement: event.currentTarget,
    })
  }

  handleCloseDropDown = () => {
    this.setState({
      anchorElement: null,
    })
  }

  render() {
    const {
      storyUser,
      created_at,
      deleteEvent,
      rank,
      preview,
      handleEvent,
      editStory,
      setShowUserCurrentPosition,
      showUnblock,
      handleUnblock,
    } = this.props

    const { anchorElement } = this.state
    let created_date = null
    let currentUser = this.props.auth.user
    const { isAuthenticated } = this.props.auth

    if (created_at) {
      created_date = new Date(created_at)
      created_date =
        String.fromCharCode(183) + ' ' + this.getCreatedDate(created_date)
    }

    let username = '@' + storyUser.username + ' '
    let deleteSign = null
    let blockUserProps = { primaryText: 'Block user' }
    let unblockUserProps = { primaryText: 'Unblock user' }
    let reportStoryProps = { primaryText: 'Report post' }

    if (!preview) {
      blockUserProps = () =>
        this.setState({ anchorElement: null }, () => handleEvent('BLOCK_USER'))
      unblockUserProps = () =>
        this.setState({ anchorElement: null }, () => handleUnblock())
      reportStoryProps = () =>
        this.setState({ anchorElement: null }, () =>
          handleEvent('REPORT_STORY')
        )
    }

    let menuItems = showUnblock ? (
      <>
        <MenuItem onClick={reportStoryProps}>Report post</MenuItem>
        <MenuItem onClick={unblockUserProps}>Unblock user</MenuItem>
      </>
    ) : (
      <>
        <MenuItem onClick={reportStoryProps}>Report post</MenuItem>
        <MenuItem onClick={blockUserProps}>Block user</MenuItem>
      </>
    )

    if (currentUser && storyUser.id === currentUser.id) {
      deleteSign = (
        <i
          className="fa fa-times delete-comment"
          aria-hidden="true"
          onClick={deleteEvent}
        />
      )
      let deleteProps = null
      let editProps = null
      let archiveProps = null

      if (!preview) {
        deleteProps = () =>
          this.setState({ anchorElement: null }, () =>
            handleEvent('DELETE_STORY')
          )
        editProps.onClick = () =>
          this.setState({ anchorElement: null }, () => {
            console.log('editProps from ShowUserPage/UserInfo')
            editStory()
          })
        archiveProps.onClick = () =>
          this.setState({ anchorElement: null }, () =>
            handleEvent('MOVE_TO_ARCHIVE')
          )
      }

      menuItems =
        rank > 0 ? (
          <>
            <MenuItem onClick={editProps} label="Edit" />
            <MenuItem onClick={archiveProps}>Move to archive</MenuItem>
            <MenuItem onClick={deleteProps}>Delete</MenuItem>
          </>
        ) : (
          <>
            <MenuItem onClick={editProps} label="Edit" />
            <MenuItem onClick={deleteProps}>Delete</MenuItem>
          </>
        )
    }
    let popover = (
      <UserInfoPopoverMenu
        className="popover-action"
        anchorElement={anchorElement}
        handleCloseDropDown={this.handleCloseDropDown}
      >
        {menuItems}
      </UserInfoPopoverMenu>
    )

    let elipseDots = (
      <i
        className="fa fa-ellipsis-v story-dropdown"
        onClick={this.handleOpenDropDown}
        aria-hidden="true"
      />
    )
    let linkTo =
      currentUser && storyUser.id === currentUser.id
        ? `/users/${currentUser.id}`
        : `/users/${storyUser.id}`

    let avatar = (
      <Link to={linkTo} onClick={e => setShowUserCurrentPosition(null)}>
        <Avatar
          src={storyUser.avatar.url}
          style={{ width: '50px', height: '50px' }}
          className="avatar"
        />
      </Link>
    )
    let userName = (
      <Box fontWeight="fontWeightBold" marginTop={0.75} display="inline-block">
        <Link to={linkTo} onClick={e => setShowUserCurrentPosition(null)}>
          {this.getFullName(storyUser)}
        </Link>
      </Box>
    )
    if (preview) {
      avatar = (
        <Avatar
          src={storyUser.avatar.url}
          style={{ width: '50px', height: '50px' }}
          className="avatar preview"
        />
      )
      userName = (
        <Box
          className="preview"
          fontWeight="fontWeightBold"
          marginTop={0.75}
          display="inline-block"
        >
          {this.getFullName(storyUser)}
        </Box>
      )
    }
    return (
      <div className="user-info">
        {avatar}
        <div className="container">
          {userName}
          {created_at ? (
            <Typography className="add-info">
              <span className="user-userName">{username}</span>
              <span className="created-at">{created_date}</span>
            </Typography>
          ) : null}
          {!created_at && deleteSign}
          {rank > 0 ? (
            <div className="number-circle">
              <span>{rank}</span>
            </div>
          ) : null}
          {isAuthenticated && created_at && elipseDots}
          {isAuthenticated && created_at && popover}
        </div>
      </div>
    )
  }
}

UserInfo.propTypes = {
  auth:          PropTypes.object.isRequired,
  showUnblock:   PropTypes.bool.isRequired,
  handleUnblock: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps, { setShowUserCurrentPosition })(
  UserInfo
)
