/**
 * Created by boris on 2/27/17.
 */
import React from 'react'
import { Route, IndexRoute, Redirect } from 'react-router'

import App from './App'
import FeedPage from './components/FeedPage'
import StoryComments from './components/StoryCommentsPage'
import MyNuuz from './components/MyNuuz'
import ShowUserPage from './components/ShowUserPage'
import LoginPage from './components/Login/LoginPage'
import SignupPage from './components/SignUp/SignUpPage'
import requireAuth from './utils/requireAuth'
import requireUnauth from './utils/requireUnauth'
import EditProfile from './components/EditProfile/EditProfilePage'
import EditPassword from './components/EditPassword/EditPasswordPage'
import AddStory from './components/AddStory/AddStoryPage'
import EditStory from './components/EditStory/EditStoryPage'
import NuuzArchive from './components/NuuzArchive'
import OtherUserNuuzArchive from './components/OtherUserNuuzArchive'
import BlacklistPage from './components/Blacklist/BlacklistPage'
import FollowersPage from './components/Followers/FollowersPage'
import FollowingsPage from './components/Followers/FollowingsPage'
import FollowingsRequestsPage from './components/Followers/FollowingsRequestsPage'
import FollowersRequestsPage from './components/Followers/FollowersRequestsPage'
import RejectsPage from './components/Followers/RejectsPage'
import SearchPage from './components/SearchPage'
import { loadFeed, initialFeed } from './actions/FeedActions'
import { loadArchive, initialArchive } from './actions/ArchiveActions'
import ReadingList from './components/ReadingList'
import AddReadingList from './components/AddReadingListPage/AddReadingListPage'
import PublicationsPage from './components/PublicationsPage'
import ResetPasswordPage from './components/ResetPassword/ResetPasswordPage'
import ActivityHistoryPage from './components/ActivityHistory/ActivityHistoryPage'
import AlertsPage from './components/Alerts/AlertsPage'

import {
  getReadingList,
  initialReadingList,
} from './actions/ReadingListActions'

import { store } from './Store'

function showUserPage(props) {
  const { isAuthenticated, user } = store.getState().auth
  if (
    isAuthenticated &&
    (user.id === +props.params.user_id ||
      user.username === props.params.user_id)
  ) {
    return <MyNuuz {...props} />
  }
  return <ShowUserPage {...props} />
}

function showUserArchivePage(props) {
  const { isAuthenticated, user } = store.getState().auth

  if (
    isAuthenticated &&
    (user.id === +props.params.user_id ||
      user.username === props.params.user_id)
  ) {
    return <NuuzArchive {...props} />
  }

  return <OtherUserNuuzArchive {...props} />
}

function googleAnalitycsLogPage(title) {
  if (!window.gtag) {
    return
  }
  window.gtag('config', window.gtagId, { page_title: title })
}

export default (
  <Route path="/" component={App}>
    <IndexRoute
      component={requireAuth(FeedPage)}
      onChange={() => {
        googleAnalitycsLogPage('web-IndexPage')
        store.dispatch(initialFeed())
        store.dispatch(loadFeed())
      }}
    />

    <Route
      path="login"
      components={requireUnauth(LoginPage)}
      onChange={() => googleAnalitycsLogPage('web-LoginPage')}
    />
    <Route
      path="register"
      components={requireUnauth(SignupPage)}
      onChange={() => googleAnalitycsLogPage('web-SignupPage')}
    />
    <Route
      path="addstory"
      component={requireAuth(AddStory)}
      onChange={() => googleAnalitycsLogPage('web-AddStory')}
    />
    <Route
      path="editstory/:story_id"
      component={requireAuth(EditStory)}
      onChange={() => googleAnalitycsLogPage('web-EditStory')}
    />
    <Route
      path="activity_history"
      component={requireAuth(ActivityHistoryPage)}
      onChange={() => googleAnalitycsLogPage('web-ActivityHistoryPage')}
    />
    <Route
      path="alerts"
      component={requireAuth(AlertsPage)}
      onChange={() => googleAnalitycsLogPage('web-AlertsPage')}
    />
    <Route
      path="users/:user_id"
      component={showUserPage}
      onChange={() => googleAnalitycsLogPage('web-showUserPage')}
    />
    <Route
      path="users/:user_id/stories/nuuz"
      component={showUserPage}
      onChange={() => googleAnalitycsLogPage('web-showUserPage')}
    />
    <Route
      path="users/:user_id/stories/nuuz_archive"
      component={showUserArchivePage}
      onChange={() => {
        googleAnalitycsLogPage('web-NuuzArchive')
        store.dispatch(initialArchive())
        store.dispatch(loadArchive(store.getState().auth.user.id))
      }}
    />
    <Route
      path="users/:user_id/stories/:story_id"
      components={StoryComments}
      onChange={() => googleAnalitycsLogPage('web-StoryComments')}
    />
    <Route
      path="editprofile"
      component={requireAuth(EditProfile)}
      onChange={() => googleAnalitycsLogPage('web-EditProfile')}
    />
    <Route
      path="editpassword"
      component={requireAuth(EditPassword)}
      onChange={() => googleAnalitycsLogPage('web-EditPassword')}
    />
    <Route
      path="blacklist"
      component={requireAuth(BlacklistPage)}
      onChange={() => googleAnalitycsLogPage('web-BlacklistPage')}
    />
    <Route
      path="users/:user_id/followers"
      component={requireAuth(FollowersPage)}
      onChange={() => googleAnalitycsLogPage('web-FollowersPage')}
    />
    <Route
      path="users/:user_id/followers/requests"
      component={requireAuth(FollowersRequestsPage)}
      onChange={() => googleAnalitycsLogPage('web-FollowersRequestsPage')}
    />
    <Route
      path="users/:user_id/followings"
      component={requireAuth(FollowingsPage)}
      onChange={() => googleAnalitycsLogPage('web-FollowingsPage')}
    />
    <Route
      path="users/:user_id/followings/requests"
      component={requireAuth(FollowingsRequestsPage)}
      onChange={() => googleAnalitycsLogPage('web-FollowingsRequestsPage')}
    />
    <Route
      path="users/:user_id/followers/rejected"
      component={requireAuth(RejectsPage)}
      onChange={() => googleAnalitycsLogPage('web-RejectsPage')}
    />
    <Redirect from="search" to="search/people" />
    <Route
      path="search/people"
      component={requireAuth(SearchPage)}
      onChange={() => googleAnalitycsLogPage('web-SearchPage people')}
    />
    <Route
      path="search/hashtags"
      component={requireAuth(SearchPage)}
      onChange={() => googleAnalitycsLogPage('web-SearchPage hashtags')}
    />
    <Route
      path="search/location"
      component={requireAuth(SearchPage)}
      onChange={() => googleAnalitycsLogPage('web-SearchPage locations')}
    />
    <Route
      path="reading_list"
      component={requireAuth(ReadingList)}
      onChange={() => {
        googleAnalitycsLogPage('web-ReadingList')
        store.dispatch(initialReadingList())
        store.dispatch(getReadingList())
      }}
    />
    <Route
      path="add_reading_list"
      component={requireAuth(AddReadingList)}
      onChange={() => googleAnalitycsLogPage('web-AddReadingList')}
    />
    <Route
      path="publication_sites"
      component={requireAuth(PublicationsPage)}
      onChange={() => googleAnalitycsLogPage('web-PublicationsPage')}
    />
    <Route
      path="reset_password"
      component={requireUnauth(ResetPasswordPage)}
      onChange={() => googleAnalitycsLogPage('web-ResetPasswordPage')}
    />
    <Route
      path="*"
      onEnter={() => store.dispatch({ type: 'SET_NOT_FOUND', notFound: true })}
      onChange={() => googleAnalitycsLogPage('web-404 page')}
    />
  </Route>
)
